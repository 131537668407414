<template>
  <el-dialog
    top="2em"
    width="80%"
    :visible.sync="secondEquipmentPanelModalVisible"
    append-to-body
    title="Устройства РЗА"
    class="incidient_primary_equip--modal"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <div class="my-flex-container-column">
      <select-second-equipment-panel-filter 
        @active-change="filterActive = $event" 
        :filter-model="filterModel" 
        @clear-filters="clearFilters" />
      <select-second-equipment-panel-table 
        :key="key"
        :filter-active="filterActive" 
        :filter-model="filterModel"       
        :items="items"
        :incident-id="incidentId"
        @change="change($event)"/>
    </div>
  </el-dialog>
</template>

<script>
import SelectSecondEquipmentPanelFilter from '@/views/Global/SecondEquipmentPanels/SecondEquipmentPanelsFilter.vue';
// import SelectSecondEquipmentPanelFilter from './SelectSecondEquipmentPanelFilter';
import SelectSecondEquipmentPanelTable from './SelectSecondEquipmentPanelTable';

export default {
  name: 'SelectSecondEquipmentPanelModal',
  components: { SelectSecondEquipmentPanelFilter, SelectSecondEquipmentPanelTable },
  props: {
      secondEquipmentPanelModalVisible: { type: Boolean, default: () => true },
      items: { type: Array, default: () => [] },
      incidentId: { type: String, default: () => '' }
  },
  watch: {
    secondEquipmentPanelModalVisible: {
      handler() {
        if (this.secondEquipmentPanelModalVisible === true) {
          this.key += 1;
        }
      }
    }
  },
  data() {
    return {
      filterModel: {
        nameFilter: '',
        eoIdFilter: '',
        skipEoObject: false,
        isLocalNsiFilter: false,
        productAssetModelIdFilter: '',
        duOrganizationName: '',
        dvOrganizationName: '',
        baseVoltageFilter: '',
        eoRegionFilter: [],
        ownerOrganizationsFilter: [],
        registrationGroupFilter: [],
        executionsFilter: [],
        techAdministrationOrganizationsFilter: [],
        techManagementOrganizationsFilter: [],
        technicalOrganizationsFilter: [],
        startCommissioningTimeRange: '',
        endCommissioningTimeRange: '',
        startDecommissioningTimeRange: '',
        endDecommissioningTimeRange: '',
        showDecommissionedSE: null
      },
      filterActive: true,
      key: 0
    };
  },
   methods: {     
    change(event) {
      this.$emit('change', event);
    },
    clearFilters() {
      this.filterModel = {
        nameFilter: '',
        eoIdFilter: '',
        skipEoObject: false,
        isLocalNsiFilter: false,
        productAssetModelIdFilter: '',
        duOrganizationName: '',
        dvOrganizationName: '',
        baseVoltageFilter: '',
        eoRegionFilter: [],
        ownerOrganizationsFilter: [],
        registrationGroupFilter: [],
        executionsFilter: [],
        techAdministrationOrganizationsFilter: [],
        techManagementOrganizationsFilter: [],
        technicalOrganizationsFilter: [],
        startCommissioningTimeRange: '',
        endCommissioningTimeRange: '',
        startDecommissioningTimeRange: '',
        endDecommissioningTimeRange: '',
        showDecommissionedSE: null
      };
    }
   }
};
</script>

<style scoped>

</style>
