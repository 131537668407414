<template>
  <div class="my-flex-container-column">
    <el-button
      type="text"
      @click="clearSelectedPrimaryEquipments"
      icon="el-icon-circle-close"
    >
      Очистить все
    </el-button>
    <div class="selected-tags">
      <el-tag
        v-for="tag in selectedPrimaryEquipments"
        :key="tag.id"
        size="small"
        :closable="!tag.disabled"
        @close="handlePrimaryEquipmentDelete(tag)"
        class="mr-1 mb-1"
      >
        {{ tag.name }}
      </el-tag>
    </div>
    <div style="text-align: center">
      <pagination
        style="text-align: center"
        :total-count="totalCount"
        :page-size.sync="pageSize"
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <div>
      <el-button @click="toggleAllNodes" type="text" class="ml-2">Свернуть/развернуть всё</el-button>
    </div>
    <div v-loading="loading" class="my-flex-data-content">
      <infinite-tree
        ref="tree"
        :data="primaryEquipments"
        :style="{ height: '100%'}"
        @on-check-click="handleCheck"
        @on-open-node="applyChecking"
      >
        <div class="infinite-tree-node" slot-scope="{ node }">{{ node.color }}</div>
      </infinite-tree>
    </div>
    <div class="dialog-footer">
      <el-button type="primary" @click="accept">Применить</el-button>
      <el-button type="default" @click="cancel">Отменить</el-button>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import primaryEquipmentsApi from '@/api/eventsAndIncidents/primaryEquipments';
import pagination from '@/components/Ui/Pagination';
import infiniteTree from '@/components/Ui/InfiniteTree/InfiniteTree';

export default {
  name: 'SelectPrimaryEquipmentTable',
  props: {
    items: { type: Array, default: () => [] },
    incidentId: { type: String, default: () => '' },
    filterActive: { type: Boolean, default: () => true },
    filterModel: { type: Object, default: () => { } },
  },
  components: {
    pagination, infiniteTree
  },
  watch: {
    filterModel: {
        handler() {
            this.getPrimaryEquipments();
        },
        deep: true
    },
  },
  async mounted() {
    this.acceptedState = cloneDeep(this.items);
    this.selectedPrimaryEquipments = this.items;

    await this.getPrimaryEquipments();
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      sortField: 'name',
      sortDesc: false,
      checked: false,
      loading: false,
      primaryEquipments: [],
      selectedPrimaryEquipments: [],
      acceptedState: [],
      psrTypeIdsFilter: [],
      tableSizeConstant: 270,
      primaryEquipmentsCount: 0
    };
  },
  computed: {
    treeContainer() {
      return this.$refs.tree;
    }
  },
  methods: {
    async getPrimaryEquipments() {
      this.loading = true;
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        nameFilter: this.filterModel.nameFilter,
        energyObjectIdFilter: this.filterModel.energyObjectIdFilter,
        duOrganizationName: this.filterModel.duOrganizationName,
        dvOrganizationName: this.filterModel.dvOrganizationName,
        baseVoltageFilter: this.filterModel.baseVoltageFilter,
        primaryEquipmentTypeIdFilter: this.filterModel.primaryEquipmentTypeIdFilter,
        skipEoObject: this.filterModel.skipEoObject,
        isLocalNsiFilter: this.filterModel.isLocalNsiFilter,
        isRuFilter: this.filterModel.isRuFilter,
        hideLines: this.filterModel.hideLines,
        sortField: this.sortField,
        sortDesc: this.sortDesc,
        ownerOrganizationsFilter: this.filterModel.ownerOrganizationsFilter,
        eoRegionFilter: this.filterModel.eoRegionFilter,
        techAdministrationOrganizationsFilter: this.filterModel.techAdministrationOrganizationsFilter,
        techManagementOrganizationsFilter: this.filterModel.techManagementOrganizationsFilter,
      };
      const res = await primaryEquipmentsApi.getPrimaryEquipments(params);
      if (res.data) {
        this.primaryEquipments = res.data.items;
        this.totalCount = res.data.totalCount;
        this.primaryEquipmentsCount = res.data.treeItemsCount;
        this.treeContainer.tree.loadData(this.primaryEquipments);

        this.applyChecking();
      }
      this.loading = false;
    },
    applyChecking() {
      const { nodes } = this.treeContainer.tree;

      nodes.forEach((node) => {
        if (node.nodeType === 'primaryEquipment') {
          if (this.selectedPrimaryEquipments.some((t) => t.primaryEquipmentId === node.primaryEquipmentId)) {
            node.state.checked = true;
            node.parent.state.checked = node.state.checked;
            node.parent.parent.state.checked = node.state.checked;
          } else {
            node.state.checked = false;
          }
          node.сheckAllowed = true;
          node.viewAllowed = false;
          node.parent.сheckAllowed = node.сheckAllowed;
          node.parent.parent.сheckAllowed = node.сheckAllowed;
        }
      });

      this.treeContainer.rerender();
    },
    handleCheck(node) {
      if (node.state.checked) {
        const newPe = cloneDeep(node);
        newPe.parent = null;
        this.selectedPrimaryEquipments.push(newPe);

        const nodes = this.treeContainer.tree.nodes.filter((t) => t.nodeType === 'primaryEquipment' && t.primaryEquipmentId === node.primaryEquipmentId);
        if (nodes && nodes.length > 0) {
          nodes.forEach((n) => {
            n.state.checked = true;
            n.parent.state.checked = true;
            n.parent.parent.state.checked = true;
          });

          this.treeContainer.rerender();
        }
      } else {
        const index = this.selectedPrimaryEquipments.findIndex((x) => x.primaryEquipmentId === node.primaryEquipmentId);
        if (index > -1) {
          this.selectedPrimaryEquipments.splice(index, 1);

          const nodes = this.treeContainer.tree.nodes.filter((t) => t.nodeType === 'primaryEquipment' && t.primaryEquipmentId === node.primaryEquipmentId);
          if (nodes && nodes.length > 0) {
            nodes.forEach((n) => {
              n.state.checked = false;
              n.parent.state.checked = false;
              n.parent.parent.state.checked = false;
            });

            this.treeContainer.rerender();
          }
        }
      }

      // this.$emit('on-update', this.selectedPrimaryEquipments);
    },
    clearSelectedPrimaryEquipments() {
      // this.selectedPrimaryEquipments = [];
      this.selectedPrimaryEquipments = this.selectedPrimaryEquipments.filter((s) => s.disabled === true);
      this.applyChecking();

      // this.$emit('on-update', this.selectedPrimaryEquipments);
    },
    handlePrimaryEquipmentDelete(pe) {
      this.selectedPrimaryEquipments = this.selectedPrimaryEquipments.filter((s) => s.primaryEquipmentId !== pe.primaryEquipmentId);
      this.applyChecking();

      // this.$emit('on-update', this.selectedPrimaryEquipments);
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getPrimaryEquipments();
    },
    async handleCurrentChange() {
      await this.getPrimaryEquipments();
      const isNodesOpen = await this.$refs.tree.isNodesOpen();
      if (!isNodesOpen) {
        await this.toggleAllNodes();
        await this.$refs.tree.toggleNodes();
      }
      this.applyChecking();
    },
    cancel() {
      this.$emit('change', this.acceptedState);
    },
    accept() {
      this.selectedPrimaryEquipments.forEach((item) => item.confirmed === true);
      this.$emit('change', this.selectedPrimaryEquipments);
    },
    async toggleAllNodes() {
      this.$refs.tree.toggleNodes();
      this.applyChecking();
    }
  },
};
</script>

<style scoped>
.el-tabs__header.is-top {
  margin: 0px !important;
}
button.el-button.el-button--text:first-child {
  margin-left: 0px;
}
.el-col > span {
  font-size: 12px;
}
div#pane-0 .el-dialog__body {
  padding-top: 0px !important;
}
div#pane-0 .el-dialog__header {
  padding-bottom: 20px !important;
}
.tdWraper > span {
  margin: auto 5px;
}
.tdWraper {
  display: flex;
}
.el-tree-node {
  padding: 5px 0px;
}
.el-tree {
  width: 100%;
  height: auto;
}
.tree-container {
  overflow-y: auto;
  max-height: 40vh;
  min-height: 40vh;
}
.el-dialog__body {
  padding-bottom: 20px !important;
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
.total_items {
   float: right;
   color: #909399;
   font-family: Montserrat-Regular;
   font-size: 14px;
   margin-top: 12px;
   margin-right: 8px;
}
</style>
